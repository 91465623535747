
import { Button } from 'antd';
import imageCompression from 'browser-image-compression';
import {Base64} from 'js-base64';

export function UploadController({ children, value, onChange, disabled, accept, name }) {
    return (
        <Button size='large' style={{ width: 200, padding: 0,borderColor: '#d9d9d9',background: '#FFFFFF',borderStyle: 'dashed' }}>
            <label htmlFor={name} style={{ width: '100%' }}>
                <input
                    value={value}
                    accept={accept}
                    disabled={disabled}
                    style={{ display: 'none', width: '100%' }}
                    id={name}
                    multiple
                    type="file"
                    onChange={onChange}
                />

                <div style={{ width: '100%'}}>{children}</div>

            </label>
        </Button>
    )
}

export const compressImage = async (file)=>{

    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
    }

    const compressedFile = await imageCompression(file, options)

    let newFile = new File([compressedFile], `${file?.name?.split('.')[0]}.png`, { type: 'image/png' });

    return newFile

}

export const getTotalBuilder = (team)=>{
    let total = 0
    team?.map(e=>{
        total+= parseInt(e?.builderCount)
    })

    return total
}

export const getTotalWorker = (team)=>{
    let total = 0
    team?.map(e=>{
        total+= parseInt(e?.workerCount)
    })

    return total
}

export const convertImageToBase64=(url)=>{
    let response =''
    toDataUrl(url,(res)=> {
        console.log(res)
        return res
    })

}

export const toDataUrl = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  };
